import {LitElement, html, css,} from 'lit';
import "../social-icons-container/social-icons-component";

export class navbarComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="header-navbar-container">
                <div id="logo-container">
                    <div class="header-navbar-logo-container"></div>
                </div>
                <div class="nav-social-container">

                    <ul id="navbar-links-ul"
                        data-aos="fade-down"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false">
                        <li class="header-navbar-li">
                            <a class="header-navbar-link-tag" href="#about-us-section">About Us</a>
                        </li>
                        <li class="header-navbar-li">
                            <a class="header-navbar-link-tag" href="#services-section">Services</a>
                        </li>
                        <li class="header-navbar-li">
                            <a class="header-navbar-link-tag" href="#footer-section">Contacts</a>
                        </li>
                    </ul>
                    <div class="social-container" data-aos="fade-up">
                        <social-icons-component></social-icons-component>
                    </div>
                </div>


            </section>
        `;
    }
}

customElements.define('navbar-component', navbarComponent);


