'use strict'
// Import our custom CSS
import '../scss/styles.scss';
import '../components/header-component/header-component';
import '../components/about-us-component/about-us-component';
import '../components/services-component/services-component';
import '../components/goto-adv-component/goto-adv-component';
import '../components/services-use-adv-component/services-use-adv-component';
import '../components/footer-component/footer-component';
import AOS from 'aos';
AOS.init({duration:1000});

