import {LitElement, html, css,} from 'lit';

export class aboutUsComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="about-us-section d-flex w-100" id="about-us-section">
                <div class="about-us-text-container"
                     data-aos="fade-up"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <h2 class="about-us-header">Who we are?</h2>
                    <p class="about-us-text">Welcome to our company! We are a small but dedicated team of
                        career professionals with over 4 years of expertise in our field. Our experience includes
                        working with international companies, allowing us to offer exceptional client services.
                        If you don't know what you want, don't worry, WE DO? </p>
                </div>
                <div class="about-us-images-container-spacer">
                    <div class="about-us-images-container">
                        <div class="about-us-images-row">
                            <div class="about-us-images-square about-us-image-1"
                                 data-aos="fade-up"
                                 data-aos-easing="ease-in-out"
                                 data-aos-delay="100"
                                 data-aos-mirror="true"
                                 data-aos-once="false"></div>
                            <div class="about-us-images-square about-us-image-2"
                                 data-aos="fade-up"
                                 data-aos-easing="ease-in-out"
                                 data-aos-delay="400"
                                 data-aos-mirror="true"
                                 data-aos-once="false"></div>
                        </div>
                        <div class="about-us-images-row">
                            <div class="about-us-images-square about-us-image-3"
                                 data-aos="fade-up"
                                 data-aos-easing="ease-in-out"
                                 data-aos-delay="200"
                                 data-aos-mirror="true"
                                 data-aos-once="false"></div>
                            <div class="about-us-images-square about-us-image-4"
                                 data-aos="fade-up"
                                 data-aos-easing="ease-in-out"
                                 data-aos-delay="500"
                                 data-aos-mirror="true"
                                 data-aos-once="false"></div>
                        </div>
                    </div>
                </div>
            </section>
        `;
    }
}

customElements.define('about-us-component', aboutUsComponent);


