import {LitElement, html, css,} from 'lit';

export class serviceUseAdvComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="service-use-adv-component-section" id="service-adv-section">
                
                <h1 class="service-use-adv-header">Advantages of our company</h1>

                <div class="adv-row">
                    <div class="adv-contract-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-easing="ease-in-out"
                         data-aos-delay="100"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-file-earmark-text-fill icon-adv" viewBox="0 0 16 16">
                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                        </svg>
                        <p class="adv-container-text">
                            We work under contract!
                        </p>
                    </div>

                    <div class="adv-quality-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-delay="200"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-star-fill icon-adv" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        <p class="adv-container-text">
                            Assure the product quality!
                        </p>
                    </div>
                </div>

                <div class="adv-row row-2">
                    <div class="adv-degree-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-delay="300"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-award-fill icon-adv" viewBox="0 0 16 16">
                            <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                        </svg>
                        <p class="adv-container-text">
                            Bachelors Degree Professionals!
                        </p>
                    </div>

                    <div class="adv-time-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-delay="400"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-alarm icon-adv icon-timer" viewBox="0 0 16 16">
                            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                        </svg>
                        <p class="adv-container-text">
                            There is always available professionals for you.
                        </p>
                    </div>
                </div>

                <div class="adv-row row-3">
                    <div class="adv-time-limit-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-delay="500"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-calendar3 icon-adv" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                        <p class="adv-container-text">
                            Need a site ASAP? No problem.
                        </p>
                    </div>

                    <div class="adv-price-container adv-service-container"
                         data-aos="zoom-in"
                         data-aos-delay="600"
                         data-aos-easing="ease-in-out"
                         data-aos-mirror="true"
                         data-aos-once="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-bank2 icon-adv" viewBox="0 0 16 16">
                            <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
                        </svg>
                        <p class="adv-container-text">
                            Economics prices, Hire us Now!
                        </p>
                    </div>
                </div>
            </section>
        `;
    }
}

customElements.define('service-use-adv-component', serviceUseAdvComponent);


