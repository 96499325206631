import {LitElement, html, css,} from 'lit';

export class gotoAdvComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="goto-adv-section">
                <h1 class="goto-adv-phrase" data-aos="zoom-in"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false">Dare to dream</h1>
                <p class="goto-adv-text">Lots of benefits and reasons to hire us!!</p>


                <button class="btn goto-adv-btn" data-aos="fade-up"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false">
                    <a href="#service-adv-section" style="color: inherit;
    font-weight: inherit;
    font-size: inherit">Advantages
                    </a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-right goto-adv-icon" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </button>

            </section>

        `;
    }
}

customElements.define('goto-adv-component', gotoAdvComponent);


