import {LitElement, html, css,} from 'lit';

export class heroComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="header-hero-section">
                <h1 class="header-hero-tittle"
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false" style="font-family: monospace">Welcome to IdoITvision</h1>
                <h5 class="header-hero-slogan"
                    data-aos="zoom-out-up"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false">We make your dream site come true.</h5>
                <button class="header-hero-button"
                        data-aos="zoom-out"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false">
                    <a href="#services-section" style="color: inherit;
    font-weight: inherit;
    font-size: inherit">Start dreaming!
                    </a>
                </button>
            </section>

        `;
    }
}

customElements.define('hero-component', heroComponent);


