
import {LitElement, html, css, } from 'lit';

export class servicesExampleComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <div class="container-card">
                <div class="box" data-aos="flip-right"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <img class="image-1" alt="">
                    <span>UPGRADE</span>
                </div>
                <div class="box" data-aos="flip-right"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <img class="image-2" alt="">
                    <span>YOUR</span>
                </div>
                <div class="box" data-aos="flip-right"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <img class="image-3" alt="">
                    <span>WEBSITE</span>
                </div>
                <div class="box" data-aos="flip-right"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <img class="image-4" alt="">
                    <span>WITH</span>
                </div>
                <div class="box" data-aos="flip-right"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="false">
                    <img class="image-5" alt="">
                    <span>US</span>
                </div>
            </div>
        `;
    }
}

customElements.define('services-example-component', servicesExampleComponent);


