import {LitElement, html, css,} from 'lit';
import '../navbar-component/navbar-component'
import '../hero-component/hero-component'

export class headerComponent extends LitElement {
    static properties = {
        version: {},
    };

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <section class="header-container">
                <navbar-component></navbar-component>
                <hero-component></hero-component>
            </section>

        `;
    }
}

customElements.define('header-component', headerComponent);


